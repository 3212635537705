<template>
  <div>
    <b-card>
      <b-row class="mb-4 mt-2">
        <b-col>
          <h3>Match Status Updater</h3>
        </b-col>
      </b-row>
      <validation-observer
        ref="form"
        v-slot="{ invalid }"
      >
        <b-card v-if="!isDataLoaded">
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <!-- Form fields go here -->

          <b-row v-if="isDataLoaded">
            <!-- Select for live matches -->
            <b-col>
              <b-form-group>
                <b-form-select v-model="form.match_id" :options="liveMatches" required>
                  <template #first>
                    <option :value="null">Select match</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <!-- Select for match status -->
            <b-col>
              <b-form-group>
                <b-form-select v-model="form.ts_status_id" :options="statusList" required>
                  <template #first>
                    <option :value="null">Select Status</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Submit and reset buttons -->
            <b-col cols="12" class="text-right">
              <LoadingButton :loading="invalid" />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import formMixin from '@/mixins/formMixin';

export default {
  components: {
    flatPickr,
  },
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded: false,
      liveMatches: [], // Array to hold live matches
      statusList: [], // Array to hold status options
      config: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      form: {
        match_id: null,
        ts_status_id: null,
      },
    };
  },
  created() {
    this.getLiveMatches();
    this.getStatusList(); // Fetch status list
  },
  methods: {
    getLiveMatches() {
      this.axios.get('/urgent-updater/matches-live')
        .then(res => {
          this.liveMatches = res.data;
          this.isDataLoaded = true; // Set data loaded flag
        })
        .catch(error => {
          console.error('Error loading live matches:', error);

        });
    },
    getStatusList() {


      this.statusList = [
        { value: 1, text: 'Not Started' },
        { value: 2, text: 'First Half' },
        { value: 3, text: 'Half Time' },
        { value: 4, text: 'Second Half' },
        { value: 5, text: 'Over Time' },
        { value: 6, text: 'Over Time Deprecated' },
        { value: 7, text: 'Penalties Shootout' },
        { value: 8, text: 'End'}
      ];
    },
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.axios.post('/urgent-updater/match-status/' + this.form.match_id, {
            ts_status_id: this.form.ts_status_id,
          })
            .then(() => {
              this.$toast.success('Match status updated successfully');
              this.$refs.form.reset();
              // REFRESH MATCHES
              this.getLiveMatches();

            })
            .catch(error => {
              console.error('Error updating match status:', error);
              this.$toast.error('Error updating match status');
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
